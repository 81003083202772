        body {
        background: rgb(255, 173, 65);
        background-image: url("../images/shopping-list.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      
      .todo-container {
        background: rgb(41, 33, 33);
        width: 40vw;
        margin: 10em auto;
        border-radius: 15px;
        padding: 20px 10px;
        color: white;
        border: 3px solid rgb(36, 110, 194);
      }
      
      .task {
        border: 1px solid white;
        border-radius: 5px;
        padding: 0.8em;
        margin: 0.5em;
      }
      
      .switch.btn-sm{
        float: inline-end;
      }
.switch-on.btn-sm{
    font-size: x-small;
    
}
.switch-off.btn-sm {
    font-size: x-small;
  
}
      .task .close {
        border-radius: 5px;
        margin: 0px 5px;
        padding: 3px 5px;
        cursor: pointer;
        float: right;
      }
      
      
      .header {
        margin: 0.5em;
        font-size: 2em;
        text-align: center;
      }
      
      .create-task input[type=text] {
        margin: 2.5em 2em;
        width: 80%;
        outline: none;
        border: none;
        padding: 0.7em;
      }
  .btn_style{
    margin: 2.5em 2em;
  }
  @media only screen and (max-width: 600px) {
    .todo-container {
        width: 96vw;
    }
  }